export const langTable = [
    {code:0, value:"Afrikaans"},
    {code:1, value:"Arabic"},
    {code:2, value:"Basque"},
    {code:3, value:"Belarusian"},
    {code:4, value:"Bulgarian"},
    {code:5, value:"Catalan"},
    {code:6, value:"Chinese"},
    {code:7, value:"Czech"},
    {code:8, value:"Danish"},
    {code:9, value:"Dutch"},
    {code:10, value:"English"},
    {code:11, value:"Estonian"},
    {code:12, value:"Faroese"},
    {code:13, value:"Finnish"},
    {code:14, value:"French"},
    {code:15, value:"German"},
    {code:16, value:"Greek"},
    {code:17, value:"Hebrew"},
    {code:18, value:"Hugarian"},
    {code:19, value:"Icelandic"},
    {code:20, value:"Indonesian"},
    {code:21, value:"Italian"},
    {code:22, value:"Japanese"},
    {code:23, value:"Korean"},
    {code:24, value:"Latvian"},
    {code:25, value:"Lithuanian"},
    {code:26, value:"Norwegian"},
    {code:27, value:"Polish"},
    {code:28, value:"Portuguese"},
    {code:29, value:"Romanian"},
    {code:30, value:"Russian"},
    {code:31, value:"SerboCroatian"},
    {code:32, value:"Slovak"},
    {code:33, value:"Slovenian"},
    {code:34, value:"Spanish"},
    {code:35, value:"Swedish"},
    {code:36, value:"Thai"},
    {code:37, value:"Turkish"},
    {code:38, value:"Ukrainian"},
    {code:39, value:"Vietnamese"},
    {code:40, value:"ChineseSimplified"},
    {code:41, value:"ChineseTraditional"},
    {code:42, value:"Unknown"}
];