export const TOOL_VERSION = '1.1.32'; // 코인/토큰 대량전송 기능 구현.

export const ResultCode = {
  SUCCESS: 0, // 성공
  INVALID_REQPARAM: 1, // 요청 객체읜 파라메터의 형식이 잘못된 경우.
  SERVER_INTERNAL_ERROR: 2, // 서버 내부 에러
  TOKEN_VERIFY_FAILED: 4, // 토큰 검증에 실패하였음.
  TOKEN_EXPIRED: 5, // 토큰의 유효기간이 지남.

  INVALID_PAGENO: 10, // 조회용 페이지번호가 유효하지 않음.

  // ACL 관련
  ACL_USER_NOT_AUTHORIZED: 21, // 해당 액션에 대한 권한이 없음.

  // DB 관련
  DB_QUERY_INTERNALERROR: 31,
  DB_QUERY_EMPTY: 32, // DB 쿼리 결과값이 없음
  DB_UPDATE_FIELD_NOTFOUND: 33, // DB 업데이트시 해당 필드없음

  // 외부서비스 관련
  ADMINAPI_NFT_GENERATEFAILED: 60, // 게임서버 어드민 API에서 NFT속성정보를 생성하는데 실패.
  AWS_S3_UPLOADOBJ_FAIL: 80,
  AWS_S3_DOWNLOADOBJ_FAIL: 81,

  // 로그인/로그아웃 관련
  AUTH_INVALID_AUTHTOKEN: 101, // 유효하지 않은 인증토큰
  AUTH_AUTHTOKEN_EXPIRED: 102, // 인증토큰의 유효기간 만료
  AUTH_LOGIN_FAILED: 105,
  AUTH_LOGIN_ACCOUNT_NOTFOUND: 111, // DB에 관련 account 정보가 없음.
  AUTH_LOGIN_INVALID_IDFORMAT: 115, // adminID 형식이 잘못되었음.(4자리 이상 20자리 이하)
  AUTH_LOGIN_INVALID_PWFORMAT: 116, // adminPW 형식이 잘못되었음.(4자리 이상 영문대소문자,특수문자 각 1개씩 포함.)
  AUTH_LOGIN_IDNOTFOUND: 117, // adminID가 존재하지 않음.
  AUTH_LOGIN_PWMISMATCHED: 118, // adminPW 암호가 DB의 것과 일치하지 않음.
  AUTH_LOGIN_ACCOUNTDISINACTIVE: 119, // 해당 계정이 비활성화되었음.
  AUTH_LOGIN_SERVERACCESSDENIED: 120, // 해당 운영툴 서버에 접근권한이 없음.

  // 계정 관련
  ACCOUNT_REQPARAM_INVALID_IDFORMAT: 211,
  ACCOUNT_REQPARAM_INVALID_PWFORMAT: 212,
  ACCOUNT_REQPARAM_INVALID_ACLFORMAT: 213,
  ACCOUNT_REQPARAM_INVALID_TARGETID: 214,
  ACCOUNT_REQPARAM_INVALID_ACTIVATIONFLAG: 215,
  ACCOUNT_NEWADMIN_NEWCONFIRM_MISMATCHED: 221, // 새 비밀번호와 확인용 비밀번호가 다름
  ACCOUNT_NEWADMIN_ALREADY_EXIST: 222, // 해당 계정ID가 이미 DB에 존재함.
  ACCOUNT_CHANGEPW_CURPW_MISMATCHED: 223, // 기존 비밀번호가 DB에 저장된 것과 다름
  ACCOUNT_CHANGEPW_NEWCONFIRM_MISMATCHED: 224, // 새 비밀번호와 확인용 비밀번호가 다름
  ACCOUNT_DELETE_MASTERID_NOTALLOWED: 225, // 마스터ID는 누구도 지울 수 없음
  ACCOUNT_ACTIVATION_MASTERID_NOTALLOWED: 226, // 마스터ID는 활성화/비활성화할 수 없음

  // 이벤트 관련
  EVENT_REQPARAM_INVALID_QUERYFILTERINFO: 310, // 요청파라메터:queryFilterInfo값이 유효하지 않음.
  EVENT_REQPARAM_INVALID_PARAM: 311,
  EVENT_REQPARAM_INVALID_EVENTID: 312,
  EVENT_REQPARAM_INVALID_EVENTTYPE: 313,
  EVENT_REQPARAM_INVALID_FILTERTYPE: 314,
  EVENT_REQPARAM_INVALID_RESERVEDEVENTFLAG: 315,
  EVENT_REQPARAM_INVALID_DURATION: 316,
  EVENT_REQPARAM_INVALID_ACTIVATIONFLAG: 317,
  EVENT_REQPARAM_INVALID_AUTORELEASETIME: 318,
  EVENT_REQPARAM_INVALID_DATA: 319,
  EVENT_REQPARAM_INVALID_EVENTIDLIST: 320,
  EVENT_DELETE_ONAIREVENT_NOTALLOWED: 321, // 현재 시행중인 이벤트는 삭제할 수 없음.
  EVENT_SENDNEWEVENT_SENDEVENTTOGAMESERVER_FAILED: 330, // 게임서버에서 새 이벤트 프리메세지를 처리중 오류가 발생하였습니다.
  EVENT_UPDATEEVENT_UPDATEEVENTTOGAMESERVER_FAILED: 331, // 게임서버에서 이벤트 프리메세지를 업데이트중 오류가 발생하였습니다.
  EVENT_DELETEEVENT_DELETEEVENTTOGAMESERVER_FAILED: 332, // 게임서버에서 이벤트 프리메세지를 삭제중 오류가 발생하였습니다.

  // 활동로그 관련
  LOG_REQPARAM_INVALID_FILTER_ADMINID: 411,
  LOG_REQPARAM_INVALID_FILTER_ACTIVITYID: 412,

  // 메세지 관련
  MESSAGE_REQPARAM_INVALID_QUERYFILTERINFO: 511,
  MESSAGE_REQPARAM_INVALID_MSGTYPE: 512,
  MESSAGE_REQPARAM_INVALID_MSGIDLIST: 513,
  MESSAGE_REQPARAM_INVALID_RESERVEDFLAG: 514,
  MESSAGE_REQPARAM_INVALID_STARTTIME: 515,
  MESSAGE_REQPARAM_INVALID_RESERVATIONFLAG: 516,
  MESSAGE_REQPARAM_INVALID_ACTIVATIONFLAG: 517,
  MESSAGE_SENDNEWMSG_SENDINGTOGAMESERVER_FAILED: 518,
  MESSAGE_SENDNEWMSG_SENDINGTOPUSHSERVER_FAILED: 519,

  // 공지사항 관련
  NOTI_REQPARAM_INVALID_QUERYFILTERINFO: 611, // 요청파라메터:queryFilterInfo값이 유효하지 않음.
  NOTI_REQPARAM_INVALID_NOTIID: 612, // 요청파라메터:notiID값이 유효하지 않음.
  NOTI_REQPARAM_INVALID_NOTITYPE: 613, // 요청파라메터:notiType값이 범위에 있지 않음.
  NOTI_REQPARAM_INVALID_SCHEDULENOTIFLAG: 614, // 요청파라메터:예약 공지사항 여부 플래그값이 유효하지 않음.
  NOTI_REQPARAM_INVALID_DURATION: 615, //요청파라메터:예약 공지사항 시작시각/종료시각 값이 유효하지 않음.
  NOTI_REQPARAM_INVALID_ACTIVATIONFLAG: 616, // 요청파라메터:공지사항 활성화/비활성화 값이 유효하지 않음.
  NOTI_REQPARAM_INVALID_NOTIIDLIST: 617, // 요청파라메터:공지사항 삭제를 위한 notiID 목록값이 유효하지 않음.
  NOTI_DELETE_ONAIRNOTI_NOTALLOWED: 618, // 현재 공지중인 공지사항은 삭제할 수 없음.
  NOTI_SENDNEWNOTI_SENDNOTITOGAMESERVER_FAILED: 650, // 새 공지사항을 game server에서 처리중 오류.
  NOTI_UPDATENOTI_UPDATENOTITOGAMESERVER_FAILED: 651, // 업데이트된 공지사항을 game server에서 처리중 오류.
  NOTI_DELETENOTI_DELETENOTITOGAMESERVER_FAILED: 652, // game server에서 공지사항 삭제처리 중 오류.

  // 유저 관련
  USER_REQPARAM_INVALID_QUERYFILTERINFO: 910,
  USER_REQPARAM_INVALID_USERID: 911,
  USER_REQPARAM_INVALID_USERTYPE: 912,
  USER_REQPARAM_INVALID_ACTIVATION: 913,
  USER_REQPARAM_INVALID_AUTORELEASETIME: 914,
  USER_REQPARAM_INVALID_SEARCHTYPE: 915,
  USER_REQPARAM_ACTLOG_INVALID_FILTERTYPE1: 916,
  USER_REQPARAM_ACTLOG_INVALID_FILTERTYPE2: 917,
  USER_REQPARAM_ACTLOG_INVALID_FILTERTYPE3: 918,
  USER_REQPARAM_ACTLOG_INVALID_USERID: 919,
  USER_REQPARAM_ACTLOG_INVALID_ACTGROUPID: 920,
  USER_REQPARAM_ACTLOG_INVALID_DURATION: 921,
  USER_REQPARAM_PAYLOG_INVALID_FILTERTYPE1: 922,
  USER_REQPARAM_PAYLOG_INVALID_FILTERTYPE2: 923,
  USER_REQPARAM_PAYLOG_INVALID_FILTERTYPE3: 924,
  USER_REQPARAM_PAYLOG_INVALID_DEVICETYPE: 925,
  USER_REQPARAM_REWARDTOUSER_INVALID_FILTERTARGETTYPE1: 926,
  USER_REQPARAM_REWARDTOUSER_INVALID_FILTERTARGETTYPE2: 927,
  USER_REQPARAM_REWARDTOUSER_INVALID_DEVICETYPE: 928,
  USER_REQPARAM_REWARDTOUSER_INVALID_USERIDLIST: 929,
  USER_REQPARAM_REWARDTOUSER_INVALID_EXPIREDATE: 930,
  USER_REQPARAM_ADDTOBLACKLIST_INVALID_USERIDLIST: 933,
  USER_REQPARAM_RELEASEFROMBLACKLIST_INVALID_USERIDLIST: 934,
  USER_REQPARAM_ADDTOBLACKLIST_AUTORELEASEFLAG_MISMATCHED_FOR_UNLIMITEDDURATION: 935,
  USER_REQPARAM_ADDTOBLACKLIST_DURATIONISTOOBIG: 936,

  // 설정 관련
  SETTING_REQPARAM_INVALID_ITEMLIST: 1001,
  SETTING_REQPARAM_INVALID_CLIENTCONFIG_ITEMLIST: 1002,
  SETTING_CLIENTCONFIG_DOWNLOADFROMS3_FAILED: 1011,
  SETTING_CLIENTCONFIG_UPLOADTOS3_FAILED: 1012,

  NFTCHECK_QUANITTY_NOTMISMATCHED: 1140,
  NFTCHECK_NFT_INTEGRITY_ALREADYTRANSFERED: 1141,
  NFTCHECK_NFT_INTEGRITY_TOKENIDNOTFOUND: 1142,
  NFTCHECK_METADATA_INTEGRITY_ACCESSFAILED: 1143,
};

// 기기 플랫폼 타입
export const PLATFORM_TYPE_ANDROID = 1;
export const PLATFORM_TYPE_IOS = 2;
export const PLATFORM_TYPE_WINDOWS = 3;
export const PLATFORM_TYPE_MACOS = 4;
export const PLATFORM_TYPE_WEB = 11;
export const PLATFORM_TYPE_ANDROID_XSOLLA = 21;

// 공지사항 타입
export const NOTIFICATION_TYPE_MAINTENANCE = 1; // 유지보수 공지사항
export const NOTIFICATION_TYPE_LOBBY = 2; // 로비 공지사항
export const NOTIFICATION_TYPE_WEBSITE = 3; // 웹사이트 공지사항
export const NOTIFICATION_TYPE_SCROLLALARM = 4; // 띠알림 공지사항

// 메세지 타입
export const MESSAGE_TYPE_INBOX = 1;
export const MESSAGE_TYPE_PUSHALARM = 2;
export const MESSAGE_TYPE_COUPON = 3;

export const MSGTARGET_TYPE_ALL = 1;
export const MSGTARGET_TYPE_ANDROID = 2;
export const MsGTARGET_TYPE_IOS = 3;
export const MSGTARGET_TYPE_USER = 4;

// 공지사항 내용 타입
export const NOTIFICATION_DATATYPE_TEXT = 0; // 텍스트
export const NOTIFICATION_DATATYPE_HTML = 1; // HTML 텍스트
export const NOTIFICATION_DATATYPE_URL = 2; // 웹사이트 주소

// 이벤트 타입
export const EVENT_TYPE_LOGINREWARD = 1; // 접속보상 이벤트

// 이벤트 진행타입
export const EVENTPROG_TYPE_READY = 1;
export const EVENTPROG_TYPE_ONAIR = 2;
export const EVENTPROG_TYPE_ENDED = 3;

// 유저 활동로그 활동 타입
export const userActionTypeTable = [
  '로그인',
  '로그아웃',
  '스테이지 클리어',
  '던전 클리어',
  '아레나 클리어',
  '탐험 클리어',
];

// 프리메세지 타입
export const PREMESSAGE_TYPE_SYSTEM = 0;
export const PREMESSAGE_TYPE_NOTICE = 1;
export const PREMESSAGE_TYPE_COUPON = 2;
export const PREMESSAGE_TYPE_USER = 3;
export const PREMESSAGE_TYPE_GUILD = 4;
export const PREMESSAGE_TYPE_EVENT = 10;
export const PREMESSAGE_TYPE_REWARD = 11;
export const PREMESSAGE_TYPE_SHOP = 100;

export const preMessageTypeTable = [
  { id: 1, name: '시스템', value: PREMESSAGE_TYPE_SYSTEM },
  { id: 2, name: '공지사항', value: PREMESSAGE_TYPE_NOTICE },
  { id: 3, name: '쿠폰', value: PREMESSAGE_TYPE_COUPON },
  { id: 4, name: '유저', value: PREMESSAGE_TYPE_USER },
  { id: 5, name: '길드', value: PREMESSAGE_TYPE_GUILD },
  { id: 6, name: '이벤트', value: PREMESSAGE_TYPE_EVENT },
  { id: 7, name: '보상', value: PREMESSAGE_TYPE_REWARD },
  { id: 8, name: '상점', value: PREMESSAGE_TYPE_SHOP },
];

// NFT 활동로그 타입
export const NFT_ACTIVITYTYPE_MINTING = 0;
export const NFT_ACTIVITYTYPE_BURNING = 1;

// 환경설정 상수값
export const SETTINGS_GROUP_BKL = 'BKL';
export const SETTINGS_GROUP_INB = 'INB';
export const SETTINGS_GROUP_PAM = 'PAM';
export const SETTINGS_GROUP_NFT = 'NFT';
export const SETTINGS_GROUP_TOKEN = 'TOKEN';

export const SETTINGS_ITEM_AUTORELEASE = 'auto_release';
export const SETTINGS_ITEM_AUTOEXPIRE = 'auto_expire';
export const SETTINGS_ITEM_AUTOREMOVE = 'auto_remove';
export const SETTINGS_ITEM_CONTRACTADDRESS = 'contract_address';
export const SETTINGS_ITEM_METADATABASEURI = 'metadata_base_uri';
export const SETTINGS_ITEM_MINTINGNUMUNIT = 'minting_num_unit';
export const SETTINGS_ITEM_XDSRANGE = 'from_xds_range';
export const SETTINGS_ITEM_XDCRANGE = 'from_xdc_range';
export const SETTINGS_ITEM_EXCRATE = 'exchange_rate';
export const SETTINGS_ITEM_FEETYPE = 'fee_type';
export const SETTINGS_ITEM_FEEVALUE = 'fee_rate';
export const SETTINGS_ITEM_GASAMOUNT = 'gas_amount';
export const SETTINGS_ITEM_GASFEE = 'gas_fee';

export default ResultCode;
