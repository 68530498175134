import React, {useState,useEffect} from 'react';

const NFTBurningListPanel = (props) => {

    return (
        <>
            <label>NFTBurningListPanel</label>
        </>
    )
};

export default NFTBurningListPanel;