import React, {useState,useEffect} from 'react';

const NFTDashboardPanel = (props) => {

    return (
        <>
            <label>NFTDashboardPanel</label>
        </>
    )
};

export default NFTDashboardPanel;