import React, {useState,useEffect} from 'react';

const NFTOwnerRankingPanel = (props) => {

    return (
        <>
            <label>NFTOwnerRankingPanel</label>
        </>
    )
};

export default NFTOwnerRankingPanel;